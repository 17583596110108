<template>
  <div class="news-page">
    <div class="big-title">
      <span>NEWS</span>
    </div>
    <ul class="news-list">
      <li v-for="(item,index) in newsList" :key="index">
        <div class="left">
          <img v-lazy="require('@/assets/images/'+item.coverImg)" alt="" class="cover">
        </div>
        <div class="right">
          <div class="right-top">
            <div class="title">{{ item.title }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="introduce" v-html="item.introduce">
          </div>
          <a target="_blank" :href="item.btnUrl" class="btn-box" v-if="item.isShowBtn">
            <img src="@/assets/images/mobile/btn_gf.png" alt="">
          </a>
        </div>
      </li>
    </ul>
    <div class="readMoreBtn" v-if="!isShowMore" @click="showMore()">
      <img src="@/assets/images/mobile/btn_readmore.png" alt="">
    </div>
  </div>
</template>

<script lang="ts">
import {reactive, ref} from "vue";


export default {
  name: "CCNews",
  components: {
  },
  setup() {
    let isShowMore = ref(false)
    let newsList = reactive([
      {
        id: 1,
        coverImg: 'pc/new4.jpg',
        title: 'Original Game Announcement',
        time: '2024/6/7',
        isShowBtn: false,
        btnUrl: '',
        introduce: "\"Safari Witness\", a Polaroid-like engaging theme board game on its coming release. We will roll out at the ORIGINS GAME FAIR 2024, Booth 625 in JUNE 20-23 | Columbus OH. At Fair, we also have exciting events such as Lucky Draw and Live Quick Quiz prepared for you! Don't miss out! "
      },
      {
        id: 2,
        coverImg: 'pc/news2.png',
        title: 'Win or Die Updates!',
        time: '2024/6/5',
        isShowBtn: true,
        btnUrl: 'https://gamefound.com/en/projects/whales-entertainment/win-or-die',
        introduce: 'Newest updates on Gamefound! We want to share more information about WOD to all followers and players!'
      },
      {
        id: 3,
        coverImg: 'pc/news1.png',
        title: 'New Games Announcement',
        time: '2024/2/10',
        isShowBtn: false,
        introduce: '2 new games are coming out! "Journey to the West", a thrilling adventure game inspired by the classic Chinese novel. And "Tailor Master", a unique strategy game that puts you in the shoes of a master tailor. Don\'t miss out! We will roll out the prototypes at the GAMA Expo 2024 on March 3rd to 7th.'
      },
      {
        id: 4,
        coverImg: 'pc/news3.png',
        title: '"Win or Die" Has Launched on Gamefound!',
        time: '2024/01/11',
        isShowBtn: true,
        btnUrl: 'https://gamefound.com/en/projects/whales-entertainment/win-or-die?ref=search',
        introduce: ' If you like the game, please back us and let us make the game come ture and better. Thank you!'
      },


    ])
    let moreNewsList =  {
      id: 5,
      coverImg: 'pc/news4.png',
      title: 'Win or Die Will Launch on Gamefound Soon!',
      time: '2024/1/10',
      isShowBtn: true,
      btnUrl: 'https://gamefound.com/en/projects/whales-entertainment/win-or-die?ref=search',
      introduce: 'Now it is preview phase, please "Follow" us on Gamefound. We will post WoD updates and relevant content on it. Any questions about WoD or crowdfunding,you can also leave comment on the page. We will get back to you ASAP.'
    };
    const showMore = () => {
      isShowMore.value = !isShowMore.value
      if(isShowMore.value) {
        newsList.push(moreNewsList)
      }else {
        newsList.splice(newsList.length-1, 1)
      }
    }
    return {
      isShowMore,
      newsList,
      showMore
    }
  }
}
</script>

<style scoped lang="less">
.news-page {
  width: 100%;
  background: #e4e4e4;
  .big-title {
    color: #000;
    margin: .5rem 0;
    span {
      padding: 0rem .8rem;
      font-size: .7rem;
      position: relative;
      line-height: 1.8rem;
      height: 1.8rem;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .2rem;
        background: #30affd;
        border-radius: 0.04rem;
      }
    }
  }
  .news-list {
    max-width: 90%;
    margin: 0 auto;
    li {
      display: flex;
      flex-direction: column;
      font-size: .52rem;
      color: #2b2b2b;
      margin-bottom: .4rem;
      padding-bottom: .6rem;
      border-bottom: .01rem solid #d1d1d1;
      .left {
        img {
          width: 100%;
          border-radius: .3rem;
        }
      }
      .right {
        .right-top {
          font-size: .57rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: .5rem;
        }
        .introduce {
          line-height: .8rem;
          color: #707070;
          text-align: left;
          margin-top: 15px;
        }
        .btn-box {
          display: flex;
          justify-content: flex-end;
          margin-top: .3rem;
          img {
            width: 6rem;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .readMoreBtn {
    border-top: .04rem solid #eaeaea;
    padding: .4rem 0;
    img {
      width: 5.5rem;
      margin: auto;
    }
  }
}

</style>