<template>
  <div class="contact">
    <div class="contact_content">
      <div id="sel5">
        <img src="@/assets/images/pc/logo2.png" alt="" class="company_logo">
        <div class="logo">
          <div class="contact_item" @click="JumpContack(1)">
            <img src="@/assets/images/contact_fb.png" alt="">
<!--            <span>Facebook</span>-->
          </div>
          <div class="contact_item" @click="JumpContack(2)">
            <img src="@/assets/images/contact_tw.png" alt="">
<!--            <span>X</span>-->
          </div>
          <div class="contact_item" @click="JumpContack(3)">
            <img src="@/assets/images/contact_bd.png" alt="">
<!--            <span>Discord</span>-->
          </div>
          <div class="contact_item" @click="JumpContack(4)">
            <img src="@/assets/images/contact_yt.png" alt="">
<!--            <span>YouTube</span>-->
          </div>
        </div>
        <div class="email">
          <h2 class="url">
            <a v-if="detail.email.length > 0" :href="'mailto://'+detail.email" class="url" >{{detail.email}}</a>
            <a href="mailto://info@whales-entertainment.com" class="url" v-else>info@whales-entertainment.com</a>          </h2>
        </div>

        <ul class="protocol" v-if="isShowAgreement">
          <li @click="jumpPage('UserAgreement')">User Agreement</li>
          <li @click="jumpPage('PrivacyPolicy')">Privacy Policy</li>
        </ul>
      </div>
      <div class="email_box">
        <div class="email_tips">
          Subscribe for our monthly Newsletter! No spam. Unsubscribe at any time.
        </div>
        <div class="input_box">
          <input type="text" v-model="EmailVal" placeholder="Email Address">
        </div>
        <div class="msgText">{{msgText}}</div>
        <div class="submit" @click="submitEmail">
          Subscribe
        </div>
      </div>
    </div>
    <div class="copyright">
      © 2024 Whales Entertainment. All Rights Reserved.
    </div>

    <teleport to="body">
      <div class="modal-overlay" v-if="refShowModal">
        <div class="modal">
          <header class="modal-header">
            <div class="content">
              <div class="title">
                Almost Ready!
              </div>
              <div class="info">
               <p>We have sent you a message with a personal confirmation link.</p>
                <p>Please check your email and follow the instructions found in our message.</p>
              </div>
              <div class="confirm_btn" @click="refShowModal = false">CLOSE</div>

            </div>
          </header>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import axios from "axios";

import {defineProps, onMounted, reactive, ref} from "vue";
import { event } from 'vue-gtag'
import {addSubscription} from "@/api";



function isEmail(s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/.test(s)
}
const router = useRouter();

const path = router.currentRoute.value.fullPath
const isShowAgreement =  (path == '/cascading-stars')

const props = defineProps({
  detail: {
    type: Object,
    default: () => ({
      email: 'info@whales-entertainment.com',   // 邮箱
      facebook_url: '',   // facebook 跳转链接
      discord_url: '',   // discord 跳转链接
    })
  }
})
const detail = reactive<any>({
  email: '',   // 邮箱
  facebook_url: '',   // facebook 跳转链接
  discord_url: '',   // discord 跳转链接
})
onMounted(() => {
  initDetail()
})
const initDetail = () => {
  if(props.detail) {
    detail.email = (props.detail.email && props.detail.email.length > 0) ? props.detail.email : ''
    detail.facebook_url = (props.detail.facebook_url && props.detail.facebook_url.length > 0) ? props.detail.facebook_url : ''
    detail.discord_url = (props.detail.discord_url && props.detail.discord_url.length > 0) ? props.detail.discord_url : ''
  }
}

const jumpPage =  (path: string) => {
  router.push({
    path: path
  })
}

const JumpContack = (type: number) => {
  let url  = ''
  switch (type) {
    case 1:
      url =  detail.facebook_url.length > 0 ?  detail.facebook_url : 'https://www.facebook.com/people/Whales-Entertainment/100094886750896/'
      break;
    case 2:
      url = 'https://twitter.com/whalesentertain'
      break;
    case 3:
      url = detail.discord_url.length > 0 ?  detail.discord_url : 'https://discord.gg/tBWdKjgCee'
      break;
    case 4:
      url = 'https://www.youtube.com/@WhalesEntertainment'
      break;
  }
  window.open(url)
}

const EmailVal = ref('')
const submitEmail = async () => {
  if (EmailVal.value.length == 0) {
    showFailed('Invalid! Please enter an email address.')
    return
  }
  if (!isEmail(EmailVal.value)) {
    showFailed('Invalid! Please enter correct email address')
    return
  }
  let jsStr = localStorage.getItem('ccenv2') || '{}'
  let ccenv = JSON.parse(jsStr)
  if (ccenv.ss !== undefined && new Date().getTime() - ccenv.ss < 1000 * 10) {
    showSucc()
    return
  }

  await addSubscription({
    "email": EmailVal.value,
  })
  EmailVal.value = ''
  msgText.value = ''
  showSucc()
  event('subscribe', {
    button_name: 'email_subscribe'
  });
  localStorage.setItem('ccenv2', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
  // axios.post("/api/subscribe/add", {
  //   "email": EmailVal.value,
  // }).then(rsp => {
  //   console.log(rsp)
  //   if (rsp.status == 200 && rsp.data['code'] == 0) {
  //     console.log('subscribe...OK')
  //
  //     return
  //   }
  //   showFailed('error')
  // })
}

const msgText = ref('')
let refShowModal = ref(false)
const showFailed = (msg: string) => {
  msgText.value = msg
  // showToast.value = true
  // setTimeout(() => {
  //   showToast.value = false
  // }, 2000)
  refShowModal.value = false
}
const showSucc = () => {
  refShowModal.value = true
}
</script>

<style scoped lang="less">
.contact {
  padding: 60px 0 37px 0;
  background: #13171c;
  font-family: HarmonyOS Sans, HarmonyOS Sans;
  color: #FFFFFF;
  text-align: center;
  .contact_content {
    max-width: 700px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .company_logo {
    width: 232px;
  }
  .email_box {
    position: relative;
    width: 260px;
    .email_tips {
      font-size: 14px;
      text-align: left;
      line-height: 22px;
    }
    .input_box {
      width: 260px;
      height: 40px;
      background: #fff;
      margin: 20px 0;
      padding: 0 12px;
      display: flex;
      align-items: center;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #000;
        font-size: 14px;
      }

    }
    .msgText {
      position: absolute;
      left: 275px;
      top: 45%;
      color: red;
      font-size: 14px;
      display: flex;
      width: 100%;
    }
    .submit {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background: #01e39a;
      color: #000;
      font-weight: bold;
      border-radius: 1px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }
}

#sel5 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
  background-size: 100% 186px;
  font-size: 20px;
}

.email {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 22px;
}

.email .ico {
  width: 22px;
  background-color: transparent;
}

.email .url {
  font-weight: lighter;
  text-align: center;
  background-color: transparent;
  margin-left: 10px;
  font-size: 15px;
}
.email .url a {
  color: #fefefe;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-top: 22px;
  .contactUs {
    font-size: 20px;
    color: #fefefe;
    margin-right: 10px;
  }
  .contact_item {
    display: flex;
    align-items: center;
    margin-left: 34px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    img {
      width: 32px;
      height: 32px;
    }
    span {
      font-size: 20px;
      color: #FFFFFF;
      margin-left: 4px;
    }
  }
}


.copyright {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  margin-top: 28px;
}

.protocol {
  color: #fff;
  margin-top: 20px;
  li {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }

}


.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);

  .modal {
    position: absolute;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }

  .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: url("@/assets/images/pc/confirm_bg.png") #252d46;
    background-size: 883px 400px;
    width: 883px;
    height: 400px;
    .content {
      width: 50%;
      position: relative;
      height: 100%;
      padding-top: 60px;
      display: flex;
      flex-direction: column;
      .title {
        color: #fff;
        font-size: 23px;
        padding:  0 25px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
      }
      .info {
        color: #fff;
        font-size: 19px;
        padding:  0 25px;
        line-height: 25px;
        margin-top: 50px;
        p {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
      .close {
        border: none;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        background-image: url("@/assets/images/close.png");
        background-size: 52px 54px;
        width: 52px;
        height: 54px;
        right: -15px;
        top: -15px;
      }
      .confirm_btn {
        color: #fff;
        width: 160px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        cursor: pointer;
        background: #489ad5;
        font-size: 22px;
        letter-spacing: 1px;
        margin: 50px auto 0;
      }
    }
  }

  .modal-content {
    margin: 10px 0;
  }
}

</style>