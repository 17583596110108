<template>
  <div class="mySwiper">
    <swiper
        :class="CheckMobileDevice() ? 'mobileSwiper' : 'pcSwiper'"
        :autoplay="{delay: 2500,stopOnLastSlide:false,disableOnInteraction: true}"
        :slides-per-view="1"
        :space-between="0"
        effect="fade"
        :speed="1500"
        :loop="true"
        :pagination="pagination"
        :navigation="{nextEl: '.swiper-button-next',prevEl: '.swiper-button-prev'}"
        :modules="modules"
    >
      <swiper-slide v-for="(item, index) in swiperList" :key="index">
        <div class="swiper_img">
          <img @click="jumpPage(item)" :src="require('@/assets/images/'+item.bannerImg)" style="width: 100%; clip:rect(100%,100%);">
          <div class="app_store_info" v-if="item.href === '/cascading-stars'">
            <a target="_blank" href="https://apps.apple.com/us/app/cascading-stars-ai-driven-ccg/id6504211228">
              <img src="@/assets/images/aiCard/mobile/app_store_text.png" alt="">
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.whalesEntertainment.cosmicDescent">
              <img src="@/assets/images/aiCard/mobile/google_play_text.png" alt="">
            </a>
          </div>
        </div>

      </swiper-slide>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
      <div :class="['swiper-pagination', CheckMobileDevice() ? 'swiper-pagination-m' : '']" ></div>
    </swiper>

  </div>

</template>
<script setup>

// Import Swiper Vue.js components
import {Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import {reactive, ref} from "vue";

import {Autoplay, EffectFade} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import '../assets/styles/swiper.css';

// import required modules
import {Pagination, Navigation} from 'swiper/modules';

import { useRouter } from "vue-router"
const router = useRouter()


import {onMounted, defineProps, toRefs, watch} from "vue";
import { CheckMobileDevice } from ".././utils/handle";
let swiperList = reactive([])
if(CheckMobileDevice()) {
  // swiperList = [
  //
  //   {
  //     href: '/win-or-die-m',
  //     bannerImg: 'mobile/swiper1.jpg',
  //     point: 'banner-win',  // 埋点名
  //   },
  //   {
  //     href: '/safari-witness-m',
  //     bannerImg: 'mobile/swiper6.jpg',
  //     point: 'banner-safari',  // 埋点名
  //   },
  //   {
  //     href: '/tailor-master-m',
  //     bannerImg: 'mobile/swiper4.jpg',
  //     point: 'banner-tailor',  // 埋点名
  //
  //   },
  //   {
  //     href: '/come-on-bite-me!-m',
  //     bannerImg: 'mobile/swiper2.jpg',
  //     point: 'banner-come',  // 埋点名
  //   },
  //   {
  //     href: '/mobileAiCard',
  //     bannerImg: 'mobile/swiper3.jpg',
  //     point: 'banner-aiCard',  // 埋点名
  //   },
  //
  //   {
  //     href: '/journey-to-the-west-m',
  //     bannerImg: 'mobile/swiper5.jpg',
  //     point: 'banner-journey',  // 埋点名
  //
  //   }
  // ]

  swiperList = [

    {
      href: '/win-or-die',
      bannerImg: 'home/index_banner1_m.png',
      point: 'banner-win',  // 埋点名

    },
    {
      href: '/safari-witness',
      bannerImg: 'home/index_banner3_m.png',
      point: 'banner-safari',  // 埋点名

    },
    {
      href: '/tailor-master',
      bannerImg: 'home/index_banner2_m.png',
      point: 'banner-tailor',  // 埋点名

    },

    {
      href: '/moving-company',
      bannerImg: 'home/index_banner4_m.png', // ?v=1是什么
      point: 'banner-moving',  // 埋点名
    },
    {
      href: '/cascading-stars',
      bannerImg: 'home/index_banner5_m.png',
      point: 'banner-cascading',  // 埋点名

    },
  ]
}else {
  swiperList = [

    {
      href: '/win-or-die',
      bannerImg: 'pc/swiper1.jpg',
      point: 'banner-win',  // 埋点名

    },
    {
      href: '/safari-witness',
      bannerImg: 'pc/swiper6.jpg',
      point: 'banner-safari',  // 埋点名

    },
    {
      href: '/tailor-master',
      bannerImg: 'pc/swiper4.jpg',
      point: 'banner-tailor',  // 埋点名

    },

    {
      href: '/come-on-bite-me!',
      bannerImg: 'pc/swiper2.jpg',
      point: 'banner-come',  // 埋点名

    },
    {
      href: '/cascading-stars',
      bannerImg: 'pc/swiper3.jpg',
      point: 'banner-cascading',  // 埋点名

    },

    {
      href: '/journey-to-the-west',
      bannerImg: 'pc/swiper5.jpg',
      point: 'banner-journey',  // 埋点名

    }
  ]

}
const pagination = {
  el: ".swiper-pagination",
  clickable: true,
  type: 'custom',
  renderCustom: function (swiper, current, total) {
    let html = ''
    for(let i = 0; i<total; i++) {
      if(i === current-1) {
        html+=`<div class="mySwiper-pagination mySwiper-pagination-active"></div>`
      }else {
        html+=`<div class="mySwiper-pagination"></div>`
      }
    }
    return html;
  }
}


const selectSwiper = (e) => {
  // const index = e.target.getAttribute('index')
  // swiper.slideToLoop(index, 1000, false)
}

const jumpPage = (item) => {
  if(item.href === '/win-or-die') {
    jumLink()
    return;
  }
  router.push({
    path: item.href,
    query: {
      channel: item.point
    }
  })
}

const jumLink = () => {
  window.open('https://gamefound.com/projects/whales-entertainment/win-or-die?refcode=84acysaoIUqKiiTRkHnSmQ', '_blank')
}
onMounted(() => {
})

const modules = [Pagination, Navigation, Autoplay, EffectFade]
</script>
<style lang="less">
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: .9rem;
  color: #999;
}
.swiper-button-prev {
  left: .6rem;
}
.swiper-button-next {
  right: .6rem;
}
.mySwiper {
  .swiper_img {
    position: relative;
    .app_store_info {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1.2rem;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 5.9rem;
        margin: 0 .35rem;
      }
    }
  }
  .mobileSwiper {
    .swiper-slide, .swiper-slide img {
      width: 100%;
    }
  }
  .pcSwiper {
    .swiper-slide, .swiper-slide img {
      width: 100%;
      vertical-align: top;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    color: #fff;
  }
  .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      .mySwiper-pagination {
        width: 30px;
        height: 6px;
        background: #8D8D8D;
        margin: 3px;
        &.mySwiper-pagination-active {
          background: #02E59F;
        }
      }
  }
  .swiper-pagination-m {
    .mySwiper-pagination {
      width: 1.2rem;
      height: 0.2rem;
      margin: 0.2rem;

    }
  }

}


</style>
