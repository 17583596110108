<template>
  <div class="header-boxs">
    <div class="header-left">
      <div class="left" />
      <div class="middle" @click="OnClick($event, 1)">
        <img src="../assets/images/log2.png" class="logo" alt="">
      </div>
      <div class="right" />
    </div>
    <div class="header-right">
      <div class="menu">
<!--        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClick($event, 1)">-->
<!--          <h1 class="normal">Home</h1>-->
<!--        </div>-->
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" :class="path == '/home' ? 'show' : ''">
          <div class="menuBox" @click="JumpPage('/home')">
            <h1 class="normal">Home</h1>
            <img src="../assets/images/pc/thr2.png" alt="" class="arrow">
          </div>
          <div class="menuChild">
            <ul>
              <li @click.stop="OnClick($event, 1, 1)">Board Games</li>
              <li @click.stop="OnClick($event, 2, 2)">Crowdfunding Game</li>
              <li @click.stop="OnClick($event, 3, 3)">Digital Games</li>
            </ul>
          </div>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="JumpPage('/team')" :class="path == '/team' ? 'show' : ''">
          <h1 class="normal">Team</h1>
        </div>
        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="JumpPage('/news')" :class="path == '/news' ? 'show' : ''">
          <h1 class="normal">News</h1>
        </div>

        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="JumpPage('/contact')" :class="path == '/contact' ? 'show' : ''">
          <h1 class="normal">Contact</h1>
        </div>
      </div>
    </div>
<!--    <div class="header-right" v-else>-->
<!--      <div class="menu2">-->
<!--        <div @mouseenter="OnEnter" @mouseleave="OnLeave" @click="OnClickChild($event, level.slice(0, index + 1).join('/').replaceAll('.', ''))" v-for="(item, index) in level" :key="index">-->
<!--          <h1 class="normal">{{ item.toUpperCase().replaceAll('_', ' ').replaceAll('.', 'HOME') }}</h1>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script setup lang="ts">
import {onMounted, defineEmits, ref, watch} from "vue";
import { useStore } from 'vuex';
import { useRouter,useRoute } from "vue-router"
const store = useStore();
const router = useRouter()
const route = useRoute()
const emit = defineEmits(['clickMenu', 'clickChildMenu'])

const path = ref('')
watch(() => route.path, (newVal) => {
 path.value = newVal
})
const OnEnter = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('normal')
    event.target.children[0].classList.add('highlight')
  }
  event.target.classList.add('hover')
  event.target.classList.add('active')
}

const OnLeave = (event) => {
  if (event.target.children.length === 1) {
    event.target.children[0].classList.remove('highlight')
    event.target.children[0].classList.add('normal')
  }
  event.target.classList.remove('hover')
  event.target.classList.remove('active')
}

const OnClick = (event, id, navIndex = 0) => {
  if(router.currentRoute.value.fullPath == '/'){
    // 首页直接跳转到锚点
    emit('clickMenu', id)
  }else {
    // 其他页面跳转到首页再定位锚点
    router.replace({
      path: '/home'
    })
    setTimeout(() => {
      emit('clickMenu', id)
    }, 400)
  }
  store.commit('setProductIndex', Number(navIndex))

}

const JumpPage = (path: string) => {
  router.replace({
    path
  })
}
const OnClickChild = (event, uri) => {
  emit('clickChildMenu', uri)
}

const splitPath = () => {
  // console.log('window.location.pathname =====>' + window.location.pathname);
  // console.log('window.location.search =====>' + window.location.search);
  return window.location.pathname.split('/');
}

const isRootPage = (sp: string[]): boolean => {
  return sp.length > 2 ? false : true;
}

let level = splitPath();
let isRoot = isRootPage(level);
level[0] = '.';
// console.log('level:' + level + ',isRoot:' + isRoot)

</script>

<style scoped lang="less">
.header-boxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: auto;
}
/* header left */
.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-left .left {
  flex: 20;
}
.header-left .logo {
  width: 138px;
}
.header-left .middle {
  flex: auto;
  float: right;
  cursor: pointer;
}

.header-left .right {
  flex: auto;
  float: right;
}

/* header right */
.header-right {
  display: flex;
}

.header-right .menu {
  white-space: nowrap;
  font-size: 0;
  display: flex;
  .menuBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.header-right .menu2 {
  white-space: nowrap;
  font-size: 0;
  display: flex;
}

.menu2 div {
  word-wrap: break-word;
  word-break: normal;
  overflow: hidden;
  width: 247px;
  height: 58px;
}

.menu2 div.hover {
  //background-image: url("@/assets/images/nav_bg.png");
  color: #9bb5cf;
  width: 247px;
}


.menu>div {
  word-wrap: break-word;
  word-break: normal;
  //width: 147px;
  min-width: 130px;
  padding: 0 10px;
  margin-right: 10px;
  height: 58px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &.show::after {
    content: '';
    width: 70px;
    height: 2px;
    background: #00FC9F;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}


.menu .arrow {
  margin-left: 4px;
  width: 14px;
}
.menu .menuChild {
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  //background: rgba(0,0,0,.7);
  background: linear-gradient( 180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);

  backdrop-filter: blur(5px) brightness(0.9);

  //border-top: 4px solid #3397fa;
  z-index: 1000;
  display: none;
}
.menu .menuChild ul {
  display: flex;
  flex-direction: column;
  min-height: 175px;
  padding: 10px 0;
}
.menu .menuChild ul li {
  height: 35px;
  padding: 0 10px;
  line-height: 35px;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.menu .menuChild ul li:hover {
  color: #cbd1d7;
  background: #3d4b56;
}
.menu>div.hover {
  //background-image: url("@/assets/images/nav_bg.png");
  //width: 147px;
  //width: 80px;
}
.menu>.active .arrow {
  transform: rotate(180deg);
}
.menu>.active .menuChild {
  display: inline-block;
}
div h1 {
  font-family: HarmonyOS Sans, HarmonyOS Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 58px;
  height: 58px;
  text-align: center;
}

div h1.highlight {
  //color: #77fbfc;
  color: #fff;

}

div h1.normal {
  //color: #9bb5cf;
  color: #fff;
}
</style>