<template>
  <div class="contact">
    <img src="@/assets/images/mobile/logo2.png" alt="" class="company_logo">
    <div id="sel5">
      <div class="logo">
        <!--        <div class="contactUs">-->
        <!--          Contact Us:-->
        <!--        </div>-->
        <img src="@/assets/images/contact_fb.png" alt="" @click="JumpContack(1)">
        <img src="@/assets/images/contact_tw.png" alt="" @click="JumpContack(2)">
        <img src="@/assets/images/contact_bd.png" alt="" @click="JumpContack(3)">
        <img src="@/assets/images/contact_yt.png" alt="" @click="JumpContack(4)">
      </div>
      <div class="email">
<!--        <img class="ico" src="@/assets/images/mail.png">-->

        <a v-if="detail.email.length > 0" :href="'mailto://'+detail.email" class="url" >{{detail.email}}</a>
        <a href="mailto://info@whales-entertainment.com" class="url" v-else>info@whales-entertainment.com</a>

      </div>

      <ul class="protocol" v-if="isShowAgreement">
        <li @click="jumpPage('UserAgreement')">User Agreement</li>
        <li @click="jumpPage('PrivacyPolicy')">Privacy Policy</li>
      </ul>
    </div>
    <div class="email_box">
      <div class="email_tips">
        Subscribe for our monthly Newsletter! No spam. Unsubscribe at any time.
      </div>
      <div class="input_box">
        <input type="text" v-model="EmailVal" placeholder="Email Address">
      </div>
      <div class="msgText">{{msgText}}</div>
      <div class="submit" @click="submitEmail">
        Subscribe
      </div>
    </div>
    <div class="copyright">
      © 2024 Whales Entertainment. All Rights Reserved.
    </div>
    <teleport to="body">
      <div class="modal-overlay" v-if="refShowModal">
        <div class="modal">
          <div class="modal-header">
            <img src="@/assets/images/mobile/confirm_bg.png" alt="" class="confirm_bg">
            <div class="content">
              <div class="title">
                Almost Ready!
              </div>
              <div class="info">
                <p>We have sent you a message with a personal confirmation link.</p>
                <p>Please check your email and follow the instructions found in our message.</p>
              </div>
              <div class="confirm_btn" @click="refShowModal = false">CLOSE</div>

            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {defineProps, onMounted, reactive, ref, watch} from "vue";
import Toast from "@/components/Toast.vue";
import { event } from "vue-gtag";
import {addSubscription} from "@/api";

const router = useRouter();
const jumpPage =  (path: string) => {
  router.push({
    path: path
  })
}

const props = defineProps({
   detail: {
     type: Object,
     default: () => ({
        email: 'info@whales-entertainment.com',   // 邮箱
        facebook_url: '',   // facebook 跳转链接
        discord_url: '',   // discord 跳转链接
     })
   }
})
const detail = reactive<any>({
  email: '',   // 邮箱
  facebook_url: '',   // facebook 跳转链接
  discord_url: '',   // discord 跳转链接
})
onMounted(() => {
  initDetail()
})
const initDetail = () => {
  if(props.detail) {
    detail.email = (props.detail.email && props.detail.email.length > 0) ? props.detail.email : ''
    detail.facebook_url = (props.detail.facebook_url && props.detail.facebook_url.length > 0) ? props.detail.facebook_url : ''
    detail.discord_url = (props.detail.discord_url && props.detail.discord_url.length > 0) ? props.detail.discord_url : ''
  }
}

const path = router.currentRoute.value.fullPath
const isShowAgreement = (path == '/mobileAiCard')
const isEmail = (s) => {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,4}){1,2})$/.test(s)
}
const JumpContack = (type: number) => {
  let url  = ''
  switch (type) {
    case 1:
      url =  detail.facebook_url.length > 0 ?  detail.facebook_url : 'https://www.facebook.com/people/Whales-Entertainment/100094886750896/'
      break;
    case 2:
      url = 'https://twitter.com/whalesentertain'
      break;
    case 3:
      url = detail.discord_url.length > 0 ?  detail.discord_url : 'https://discord.gg/tBWdKjgCee'
      break;
    case 4:
      url = 'https://www.youtube.com/@WhalesEntertainment'
      break;
  }
  window.open(url)
}

const EmailVal = ref('')
const submitEmail = async () => {
  if (EmailVal.value.length == 0) {
    showFailed('Invalid! Please enter an email address.')
    return
  }
  if (!isEmail(EmailVal.value)) {
    showFailed('Invalid! Please enter correct email address')
    return
  }
  let jsStr = localStorage.getItem('ccenv2') || '{}'
  let ccenv = JSON.parse(jsStr)
  if (ccenv.ss !== undefined && new Date().getTime() - ccenv.ss < 1000 * 10) {
    showSucc()
    return
  }
  await addSubscription({
    "email": EmailVal.value,
  })
  EmailVal.value = ''
  msgText.value = ''
  showSucc()
  event('subscribe', {
    button_name: 'email_subscribe'
  });
  localStorage.setItem('ccenv2', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
  // axios.post("/api/subscribe/add", {
  //   "email": EmailVal.value,
  // }).then(rsp => {
  //   console.log(rsp)
  //   if (rsp.status == 200 && rsp.data['code'] == 0) {
  //     console.log('subscribe...OK')
  //     EmailVal.value = ''
  //     msgText.value = ''
  //     showSucc()
  //     event('subscribe', {
  //       button_name: 'email_subscribe'
  //     });
  //     localStorage.setItem('ccenv2', JSON.stringify(JSON.parse('{"ss":' + new Date().getTime() + '}')))
  //     return
  //   }
  //   showFailed('error')
  // })
}

const msgText = ref('')
let refShowModal = ref(false)
const showFailed = (msg: string) => {
  msgText.value = msg
  refShowModal.value = false
}
const showSucc = () => {
  refShowModal.value = true
}
</script>

<style scoped lang="less">
.contact {
  background: #252c32;
  color: #fcfcfc;
  padding: 1.5rem 0;
  text-align: center;
  font-size: .6rem;
  position: relative;
  z-index: 9;
  .company_logo {
    width: 5.6rem;
    margin: auto;
  }
  .email_box {
    width: 13rem;
    margin: .6rem auto 0;
    .email_tips {
      font-size: 0.6rem;
      line-height: 0.9rem;
    }
    .input_box {
      width: 13rem;
      height: 2rem;
      padding: 0 .6rem;
      display: flex;
      justify-content: center;
      background: #fff;
      margin: .8rem 0 0;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        font-size: 0.7rem;
        //color: #999999;
        color: #000;
      }
    }
    .msgText {
      font-size: .6rem;
      color: red;
      margin-top: .5rem;
    }
    .submit {
      width: 6rem;
      height: 2rem;
      background: #00E59B;
      color: #000;
      border-radius: 0.05rem;
      font-size: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem auto 0;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .55rem;
    margin-top: 0.6rem;
    img {
      width: 1.4rem;
      margin-left: 1.4rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .6rem;
    .ico {
      width: .9rem;
    }
    .url {
      color: #fcfcfc;
      font-style: normal;
      font-weight: 500;
      margin-left: .4rem;
      font-size: .62rem;
      line-height: normal;
    }
  }
}
.copyright {
  margin-top: .85rem;
}

.protocol {
  color: #fff;
  margin-top: .5rem;
  li {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin-right: .5rem;
    font-size: .6rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

.modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);


  .modal {
    position: absolute;
    z-index: 9999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .confirm_bg {
    width: 90%
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #252d46;
    img {
      width: 17.25rem;
      margin: auto;
    }
    .content {
      width: 100%;
      position: absolute;
      top: 11.6rem;
      left: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      //font-family: "Aleo Regular";
      .title {
        color: #fff;
        font-size: .9rem;
        padding:  0 .3rem;
        font-weight: bold;
        letter-spacing: .05rem;
      }
      .info {
        color: #C7C7C7;
        font-size: 0.65rem;
        padding:  0 1.2rem;
        line-height: 1.05rem;
        margin-top: .6rem;
        p {
          &:first-child {
            margin-bottom: .4rem;
          }
        }
      }

      .confirm_btn {
        color: #FFFFFF;
        width: 5.95rem;
        height: 2.05rem;
        line-height: 2.05rem;
        text-align: center;
        cursor: pointer;
        background: #30AFFE;
        font-size: 0.7rem;
        letter-spacing: .05rem;
        margin: .8rem auto 0;
      }
    }
  }

  .modal-content {
    margin: 10px 0;
  }
}




</style>