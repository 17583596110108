import { createApp } from 'vue'
import App from './App.vue'
import Sticky from "vue3-sticky-directive"
import router from './router'
import store from './store'
import lazyPlugin from 'vue3-lazy'
import VueGtag from "vue-gtag";
import '@/assets/iconfont/iconfont.css';   // 引入阿里云字体图标css
import 'video.js/dist/video-js.css';

import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';
// function setRem() {
//     let scale = document.documentElement.clientWidth / 1920
//     scale = scale > 1 ? 1 : scale;

//     const realFont = scale * 100
//     document.documentElement.style.fontSize = realFont + 'px'
//     console.log('scale:', scale, ' ,setRem:', realFont)
// }

// setRem()
// window.onresize = () => {
//     setRem()
//     console.log('isMobile:', Vue.config.globalProperties.$mobile)
// }
import Vant from 'vant'
import 'vant/lib/index.css'


const isMobile = () => {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) !== null
}


export const Vue = createApp(App)
Vue.config.globalProperties.$mobile = isMobile()

// createApp(App).use(store).use(router).use(Sticky).mount('#app')
Vue.use(store).use(router).use(Sticky).use(vue3PhotoPreview).use(Vant).use(lazyPlugin, {
}).use(VueGtag, {
    config: {
        id: "G-1QL8XKQKRF"
    },
    // appName: 'whales-entertainment.com',
    pageTrackerEnabled: true, // 启用页面追踪
    // pageCheckInterval: 2000, // 每2秒检查一次路由变化
}, router).mount('#app')
