<template>
  <div id="sel1">
    <CCSwiper/>
  </div>
  <!-- products -->
  <div id="sel2">
    <CCProducts/>
  </div>
  <!-- news -->
  <div id="sel3">
    <CCNews />
  </div>
  <!-- team -->
  <div id="sel4">
    <CCTeam />
  </div>

  <!--  form  -->
<!--  <CCForm></CCForm>-->

  <!-- CCPartner -->
  <CCPartner></CCPartner>
  <!-- contact -->
  <CCContact />
</template>

<script setup>
import {ref, reactive} from "vue"
import CCSwiper from "@/components/CCSwiper.vue"
import CCForm from "./components/form.vue"
import CCPartner from "@/components/CCPartner.vue"
import CCNews from "./components/news.vue";
import CCProducts from "./components/products.vue";
import CCTeam from "./components/team.vue";
import CCBlog from "@/views/CCBlog/CCIndex.vue";
import CCContact from "./components/contact.vue";


import {event} from "vue-gtag";
import { useRoute } from 'vue-router'
const route = useRoute()

if(route.query.channel) {
  const channel = route.query.channel
  event(String(channel), {
    button_name: String(channel)
  });
}

const bannerSwiperList = reactive([
  {
    href: 'https://www.kickstarter.com/projects/1486633989/win-or-die',
    bannerImg: 'pc/swiper_img1.png'
  },
  {
    href: 'https://www.kickstarter.com/projects/1486633989/win-or-die',
    bannerImg: 'pc/swiper_img2.png'
  }


])


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.big-title {
  color: #000;
  letter-spacing: 1px;
  span {
    padding: 0 40px;
    font-size: 36px;
    position: relative;
    line-height: 90px;
    height: 90px;
    display: inline-block;
    font-family: "Aleo Regular";
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 12px;
      background: #30affd;
      border-radius: 2px;
    }
  }

}
</style>
